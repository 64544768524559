import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { FaBars, FaTimes } from "react-icons/fa";
import loader from "../assets/fuzzads-loader.gif";
import logo from "../assets/icons/Small_Logo.png"
import "../styles/navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [phone, setPhone] = useState("");
  const [userInitials, setUserInitials] = useState(null);
  const [loading, setLoading] = useState(false);
  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false);
  const [activeLink, setActiveLink] = useState("");
  const [isNavigating, setIsNavigating] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick = () => setClick(!click);
  const togglePopup = () => setIsOpen(!isOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const scrollToTop = () => {
    window.scrollTo({ bottom: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const changeColor = () => {
    if (window.scrollY >= 100) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = {
      name: event.target.name.value,
      location: event.target.location.value,
      phone: phone,
    };

    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/form",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        event.target.reset();
        setPhone("");
        setIsOpen(false);
      } else {
        console.error("Error:");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInitials");
    navigate("/login");
  };

  useEffect(() => {
    window.addEventListener("scroll", changeColor);

    const initials = localStorage.getItem("userInitials");
    if (initials) {
      setUserInitials(initials);
    }

    return () => {
      window.removeEventListener("scroll", changeColor);
    };
  }, []);

  // Line loader effect when navigating
  useEffect(() => {
    let timeoutId;
    setIsNavigating(true); // Set navigating state to true at start

    timeoutId = setTimeout(() => {
      setIsNavigating(false); // Set navigating state to false after 1 second
    }, 1000); // Adjust duration as needed

    return () => {
      clearTimeout(timeoutId); // Clean up timeout when component unmounts
    };
  }, [location.pathname]); // Trigger when location changes

  return (
    <>
      <header className="nav-header">
        {isNavigating && <div className="loader-bar"></div>} {/* Loader Bar */}
        {/*Top Strip */}
        <div
          className="d-none d-sm-flex justify-content-between align-items-center p-1 text-white"
          style={{ backgroundColor: "#00a5e0" }}
        >
          <div
            className="d-flex justify-content-between w-100 fw-bold"
            style={{ fontSize: "12px" }}
          >
            <a
              href="mailto:contact@fuzzads.com"
              className="text-white text-decoration-none me-3 ms-3"
              style={{ fontSize: "16px" }}
            >
              <i className="fas fa-envelope me-1"></i> contact@fuzzads.com
            </a>
            <span
              className="text-white cursor-pointer mx-auto"
              onClick={togglePopup}
              style={{ cursor: "pointer", fontSize: "16px" }}
            >
              Request a Call!
            </span>
            <a
              href="tel:+919310930177"
              className="text-white text-decoration-none me-3"
              style={{ fontSize: "16px" }}
            >
              <i className="fas fa-phone me-2"></i> +91 93109-30177
            </a>
          </div>
        </div>
        {/*Main navbar */}
        <nav
          className={`navbar navbar-expand-lg ${
            color ? "bg-light" : "bg-transparent"
          }`}
        >
          <div className="container fw-bold">
            <a
              href="/"
              className="navbar-brand fs-1"
              onClick={(e) => {
                scrollToTop();
                setActiveLink("/");
              }}
            >
              <img src={logo} alt="FuzzAds" style={{width: '200px', transition: "transform 0.2s" }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.1)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }  />
            </a>
            <button className="navbar-toggler" onClick={handleClick}>
              {click ? <FaTimes size={20} /> : <FaBars size={20} />}
            </button>
            <div className={`collapse navbar-collapse ${click ? "show" : ""}`}>
              <ul className="navbar-nav mx-auto">
                <li className="nav-item my-2 mx-3">
                  {" "}
                  {/* Use this for added space */}
                  <a
                    href="/"
                    className={`nav-link ${activeLink === "/" ? "active" : ""}`}
                    onClick={() => {
                      scrollToTop();
                      setActiveLink("/");
                    }}
                    style={{ color: "#26017b" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00a5e0";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#26017b";
                    }}
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item my-2 mx-3">
                  <a
                    href="/services"
                    className={`nav-link ${
                      activeLink === "/services" ? "active" : ""
                    }`}
                    onClick={() => {
                      scrollToTop();
                      setActiveLink("/services");
                    }}
                    style={{ color: "#26017b" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00a5e0";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#26017b";
                    }}
                  >
                    Services
                  </a>
                </li>
                <li className="nav-item my-2 mx-3">
                  <a
                    href="/plans"
                    className={`nav-link ${
                      activeLink === "/plans" ? "active" : ""
                    }`}
                    onClick={() => {
                      scrollToTop();
                      setActiveLink("/plans");
                    }}
                    style={{ color: "#26017b" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00a5e0";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#26017b";
                    }}
                  >
                    Plans
                  </a>
                </li>
                <li className="nav-item my-2 mx-3">
                  <a
                    href="/contact"
                    className={`nav-link ${
                      activeLink === "/contact" ? "active" : ""
                    }`}
                    onClick={() => {
                      scrollToTop();
                      setActiveLink("/contact");
                    }}
                    style={{ color: "#26017b" }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "#00a5e0";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "#26017b";
                    }}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>

              <div className="navbar-nav">
                {userInitials ? (
                  <div className="nav-item dropdown fw-bold">
                    <button
                      className="nav-link dropdown-toggle btn fw-bold"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: "0",
                        color: '#26017b',
                        fontWeight: 'bold'
                      }}
                    >
                      {userInitials}
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <li>
                        <button
                          className="dropdown-item fw-bold"
                          style={{color: '#26017b', backgroundColor: 'white'}}
                          onClick={handleLogout}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.backgroundColor = "#26017b";
                            e.currentTarget.style.color = 'white';
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.backgroundColor = 'white';
                            e.currentTarget.style.color = '#26017b';
                          }}
                        >
                          Logout
                        </button>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <Link
                    to="/plans"
                    className="btn btn-primary nav-link"
                    onClick={scrollToTop}
                    style={{
                      backgroundColor: "#00a5e0",
                      color: "white",
                      borderRadius: "100px",
                      border: "2px solid #00a5e0",
                      transition: "background-color 0.5s ease, color 0.5s ease",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = "white";
                      e.currentTarget.style.color = "#00a5e0";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "#00a5e0";
                      e.currentTarget.style.color = "white";
                    }}
                  >
                    Get Started
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
        {isOpen && (
          <>
            {/* Backdrop overlay */}
            <div
              className="position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-50"
              style={{ zIndex: "9999" }}
            ></div>

            {/* Modal */}
            <div
              className="modal show d-block"
              tabIndex="-1"
              role="dialog"
              style={{ zIndex: "10000" }}
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div
                    className="modal-header"
                    style={{ borderBottom: "none" }}
                  >
                    <h5
                      className="modal-title fs-4 fw-bold"
                      style={{ color: "#26017b" }}
                    >
                      Request a Call
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      aria-label="Close"
                      onClick={togglePopup}
                      style={{ color: "#26017b" }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label
                          htmlFor="name"
                          className="form-label fw-bold fs-5"
                          style={{ color: "#26017b" }}
                        >
                          Name:
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Enter Your Name"
                          required
                          className="form-control"
                          style={{
                            border: "2px solid #00a5e0",
                            borderRadius: "20px",
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="location"
                          className="form-label fw-bold fs-5"
                          style={{ color: "#26017b" }}
                        >
                          Location:
                        </label>
                        <input
                          type="text"
                          id="location"
                          name="location"
                          placeholder="Enter Your Location"
                          required
                          className="form-control"
                          style={{
                            border: "2px solid #00a5e0",
                            borderRadius: "20px",
                          }}
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="phone"
                          className="form-label fs-5 fw-bold"
                          style={{ color: "#26017b" }}
                        >
                          Phone Number:
                        </label>
                        <div className="d-flex align-items-center">
                          <div className="me-2" style={{ width: "100%" }}>
                            {" "}
                            {/* Ensure full width */}
                            {/* Wrapping the PhoneInput for better layout */}
                            <PhoneInput
                              placeholder="Enter phone number"
                              value={phone}
                              onChange={setPhone}
                              defaultCountry="IN"
                              id="phone"
                              name="phone"
                              className="form-control phone-input-custom"
                              required
                              style={{
                                width: "100%", // Ensure full width
                                border: "2px solid #00a5e0",
                                borderRadius: "20px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn w-100 fw-bold mt-3"
                        style={{
                          backgroundColor: "#26017b",
                          color: "#fff",
                          borderRadius: "100px",
                          border: "2px solid #26017b",
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.backgroundColor = "#26017b";
                          e.currentTarget.style.color = "white";
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.backgroundColor = "white";
                          e.currentTarget.style.color = "#26017b";
                        }}
                      >
                        Request a Call Back
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {loading && (
          <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50"
            style={{ zIndex: "10000" }}
          >
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Navbar;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/contact.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-input-2/lib/style.css";
import telephone from "../assets/icons/telephone-call.png";
import email from "../assets/icons/email.png";
import meeting from "../assets/icons/meeting.png";
import support from "../assets/icons/help-web-button.png";
import facebook from "../assets/icons/facebook.png";
import instagram from "../assets/icons/instagram.png";
import twitter from "../assets/icons/twitter.png";
import youtube from "../assets/icons/youtube.png";
import linkedin from "../assets/icons/linkedin.png";
import profile from "../assets/icons/profile.png";
import growth from "../assets/icons/growth-chart.png";
import quote from "../assets/icons/quote.png";
import loader from "../assets/fuzzads-loader.gif";
import { motion } from "framer-motion";

const Contact = ({ scrollToDemo }) => {
  const leftToRightVariant = {
    hidden: { opacity: 0, x: -100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 }, // Start hidden below the original position
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }, // Fade in to the original position
  };
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleWhatsAppClick = () => {
    const phoneNumber = "+919310930177";
    const message = "Hello, I want to enquire about ";
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    window.open(whatsappURL, "_blank");
  };

  const handleBookMeetingClick = () => {
    // Navigate to the homepage and pass state to indicate scroll to Freedemo
    navigate("/", { state: { scrollToDemo: true } });
  };

  const handleServiceClick = () => {
    navigate("/services");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = {
      name: e.target[0].value,
      phone: phone,
      email: e.target[2].value,
      businessName: e.target[3].value,
      businessDescription: e.target[4].value,
      query: e.target[5].value,
    };

    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/contact",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        navigate("/thank-you");
      } else {
        navigate("/error");
      }

      e.target.reset();
      setPhone("");
    } catch (error) {
      console.error("Error:", error);
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Loader Overlay */}
      {loading && (
        <div className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center bg-dark bg-opacity-50">
          <img
            src={loader}
            alt="Loading..."
            style={{ width: "250px", height: "250px" }}
          />
        </div>
      )}
      
      <div className={`mb-5 contact-container ${loading ? "blur" : ""}`}>
        <div
          className="contact-video d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100px", width: "100%", position: "relative" }}
        >
          <h2
            className="fw-bold text-center"
            style={{
              position: "relative",
              zIndex: 1,
              fontSize: "4rem",
              color: "#26017b",
            }}
          >
            Contact Us
          </h2>
          <p
            className="fst-italic text-center fs-4 fw-bold"
            style={{ color: "#00a5e0" }}
          >
            "Let’s make your brand shine in the digital world!"
          </p>
        </div>

        <motion.div
          className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 mt-4 mx-auto p-3"
          initial="hidden"
          whileInView="visible"
          variants={bottomToTopVariant}
          viewport={{ once: true }}
          style={{ maxWidth: "1300px" }} // Keeps max width constraint for the container
        >
          <div
            className="col text-center p-3 mx-auto" // Use mx-auto to center align items
            style={{
              border: "2px solid #26017b",
              minHeight: "200px",
              maxWidth: "260px",
            }}
          >
            <img
              src={telephone}
              alt="Box 1"
              className="img-fluid mb-2"
              style={{ width: "80px", height: "80px" }} // Reduced icon size
            />
            <p className="fw-bold" style={{ color: "#26017b" }}>
              +91 9310930177
            </p>
            <p className="fw-bold" style={{ color: "#00a5e0" }}>
              Contact us/WhatsApp
            </p>
          </div>

          <div
            className="col text-center p-3 mx-auto" // Use mx-auto to center align items
            style={{
              border: "2px solid #26017b",
              minHeight: "200px",
              maxWidth: "260px",
            }}
          >
            <img
              src={email}
              alt="Box 2"
              className="img-fluid mb-2"
              style={{ width: "80px", height: "80px" }} // Reduced icon size
            />
            <p
              className="fw-bold"
              style={{ textDecoration: "underline", color: "#26017b" }}
            >
              support@fuzzads.com
            </p>
            <p className="fw-bold" style={{ color: "#00a5e0" }}>
              Email Address
            </p>
          </div>

          <div
            className="col text-center p-3 mx-auto" // Use mx-auto to center align items
            style={{
              border: "2px solid #26017b",
              minHeight: "200px",
              maxWidth: "260px",
            }}
          >
            <img
              src={meeting}
              alt="Box 3"
              className="img-fluid mb-2"
              style={{ width: "80px", height: "80px" }} // Reduced icon size
            />
            <p className="fw-bold" style={{ color: "#26017b" }}>
              Connect Us with Meet
            </p>
            <p
              className="fw-bold"
              style={{ color: "#00a5e0", fontSize: "1rem" }} // Adjusted font size
            >
              Mon - Fri: 10:00am - 10:00pm
            </p>
          </div>

          <div
            className="col text-center p-3 mx-auto" // Use mx-auto to center align items
            style={{
              border: "2px solid #26017b",
              minHeight: "200px",
              maxWidth: "260px",
            }}
          >
            <img
              src={support}
              alt="Box 4"
              className="img-fluid mb-2"
              style={{ width: "80px", height: "80px" }} // Reduced icon size
            />
            <p className="fw-bold" style={{ color: "#26017b" }}>
              Expert Help Support
            </p>
            <p className="fw-bold" style={{ color: "#00a5e0" }}>
              24/7 Everyday
            </p>
          </div>
        </motion.div>

        <div className="container ">
          <div className="row mt-5">
            {/* Left Contact Section */}
            <motion.div
              className="col-12 col-md-6 mb-4 maincontact-form"
              initial="hidden"
              whileInView="visible"
              variants={leftToRightVariant}
              viewport={{ once: true }}
            >
            
              <h2 className="text-left fw-bold" style={{ color: "#26017b" }}>
                Let's Connect to Grow
              </h2>
              <p
                className="font-weight-bold fw-400"
                style={{ color: "#00a5e0" }}
              >
                Get in touch with us! Whether you have questions, need support,
                or want to discuss your next project, we're here to help. Fill
                out the form and we'll get back to you soon!
              </p>

              <form onSubmit={handleSubmit}>
                {/* Name Field */}
                <div className="form-group mb-3">
                  <label className="fw-bold mb-2" style={{ color: "#26017b" }}>
                    NAME
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Full Name*"
                    required
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "50px",
                    }}
                  />
                </div>

                {/* Phone Field */}
                <div className="form-group mb-3">
                  <label className="fw-bold mb-2" style={{ color: "#26017b" }}>
                    PHONE
                  </label>
                  <PhoneInput
                      placeholder="Enter phone number*"
                      value={phone}
                      onChange={setPhone}
                      defaultCountry="IN"
                      id="phone"
                      name="phone"
                      className="form-control phone-input-custom"
                      required
                      style={{
                        width: "100%", // Ensure full width
                        border: "2px solid #26017b",
                        borderRadius: "100px",
                      }}
                    />
                </div>

                {/* Email Field */}
                <div className="form-group mb-3">
                  <label className="fw-bold mb-2" style={{ color: "#26017b" }}>
                    EMAIL
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email ID*"
                    required
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "50px",
                    }}
                  />
                </div>

                {/* Business Name */}
                <div className="form-group mb-3">
                  <label className="fw-bold mb-2" style={{ color: "#26017b" }}>
                    BUSINESS NAME
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Business Name*"
                    required
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "50px",
                    }}
                  />
                </div>

                {/* Business Description */}
                <div className="form-group mb-3">
                  <label className="fw-bold mb-2" style={{ color: "#26017b" }}>
                    BUSINESS DESCRIPTION
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Business Description*"
                    required
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "50px",
                    }}
                  />
                </div>

                {/* Query Field */}
                <div className="form-group mb-3">
                  <label className="fw-bold mb-2" style={{ color: "#26017b" }}>
                    QUERY
                  </label>
                  <textarea
                    className="form-control"
                    rows="4"
                    placeholder="Give a short description about your Query"
                    required
                    style={{
                      border: "2px solid #26017b",
                      borderRadius: "10px",
                    }}
                  ></textarea>
                </div>

                {/* Submit Button */}
                <button type="submit" className="btn btn-block fw-bold">
                  Let's Grow <img src={growth} alt="growth-chart" />
                </button>
              </form>
            </motion.div>

            {/* Right Contact Section */}
            <motion.div
              className="col-12 col-md-6 d-flex flex-column align-items-center align-items-md-end" // Center items in mobile, align right in desktop
              initial="hidden"
              whileInView="visible"
              variants={rightToLeftVariant}
              viewport={{ once: true }}
            >
              {/* Quick Links */}
              <div className="mb-4" style={{ width: "70%" }}>
                <h2
                  className="mb-2 fw-bold text-center text-md-start"
                  style={{ color: "#26017b" }}
                >
                  Quick Links &gt;&gt;
                </h2>
                <button
                  className="btn w-100 mb-2 fw-bold"
                  onClick={handleBookMeetingClick}
                  style={{
                    border: "2px solid #26017b",
                    color: "#26017b",
                    borderRadius: "0px",
                    transition: "transform 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.03)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                >
                  Book a Meeting
                </button>
                <a
                  className="btn w-100 mb-2 fw-bold"
                  href="/services"
                  style={{
                    border: "2px solid #26017b",
                    color: "#26017b",
                    borderRadius: "0px",
                    transition: "transform 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.03)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                >
                  Our Services
                </a>
                <button
                  className="btn w-100 mb-2 fw-bold"
                  onClick={handleWhatsAppClick}
                  style={{
                    border: "2px solid #26017b",
                    color: "#26017b",
                    borderRadius: "0px",
                    transition: "transform 0.2s",
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.transform = "scale(1.03)")
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.transform = "scale(1)")
                  }
                >
                  Connect us on Whatsapp
                </button>
                <button
                  className="btn w-100 mb-0 fw-bold"
                  onClick={handleWhatsAppClick}
                  style={{
                    border: "2px solid #26017b",
                    color: "#26017b",
                    borderRadius: "0px",
                  }}
                >
                  Social Links
                </button>
              </div>

              {/* Social Links */}
              <div
                className="mb-5 d-flex justify-content-center"
                style={{ width: "70%" }}
              >
                <a
                  href="https://www.facebook.com/FuzzAds.co"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-1"
                >
                  <img
                    src={facebook}
                    alt="facebook"
                    className="img-fluid"
                    style={{ width: "40px", transition: "transform 0.2s" }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/fuzzads.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-1"
                >
                  <img
                    src={instagram}
                    alt="instagram"
                    className="img-fluid"
                    style={{ width: "40px", transition: "transform 0.2s" }}
                  />
                </a>
                <a
                  href="https://x.com/Fuzz_Ads"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-1"
                >
                  <img
                    src={twitter}
                    alt="twitter"
                    className="img-fluid"
                    style={{ width: "40px", transition: "transform 0.2s" }}
                  />
                </a>
                <a
                  href="https://www.youtube.com/@Fuzz-Ads"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-1"
                >
                  <img
                    src={youtube}
                    alt="youtube"
                    className="img-fluid"
                    style={{ width: "40px", transition: "transform 0.2s" }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/fuzzads/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mx-1"
                >
                  <img
                    src={linkedin}
                    alt="linkedin"
                    className="img-fluid"
                    style={{ width: "40px", transition: "transform 0.2s" }}
                  />
                </a>
              </div>

              {/* Quote Box */}
              <div
                className="p-3 "
                style={{ border: "2px solid #26017b", width: "70%" }}
              >
                <img src={quote} alt="quote" className="img-fluid mb-4" />
                <p
                  className="fw-bold fs-5 text-center p-2"
                  style={{ color: "#00a5e0", textAlign: "justify" }}
                >
                  Great brands aren't built overnight—they're crafted with
                  passion, persistence, and innovation. At{" "}
                  <span style={{ color: "#26017b" }}>Fuzz</span>
                  <span style={{ color: "#00a5e0" }}>Ads</span>, we believe in
                  pushing boundaries, embracing creativity, and driving growth.
                  Success is a journey, and every step forward counts. Let's
                  rise, create, and conquer together!
                </p>

                <div className="d-flex align-items-center mt-1 justify-content-center mb-2">
                  <img
                    src={profile}
                    alt="profile"
                    className="img-fluid rounded-circle mx-2"
                    style={{ width: "60px", height: "60px" }}
                  />
                  <div className="ml-3 text-center">
                    <h5 className="mb-0 fw-bold" style={{ color: "#26017b" }}>
                      Pratham Choudhary
                    </h5>
                    <span className="fs-6" style={{ color: "#00a5e0" }}>
                      Founder/CEO
                    </span>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

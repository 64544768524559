import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import PricingCards from "./PricingCards";
import { Modal } from "react-bootstrap"; // Import Modal from Bootstrap
import PlanForm from "./PlanForm";

const plansData = [
  {
    image: require("../assets/icons/web-dev.png"),
    heading: "Custom Website Designs for Your Success",
    content:
      "From modern portfolios to strong platforms, we create websites that match your vision. Choose features to help your business grow.",
  },
  {
    image: require("../assets/icons/app-dev.png"),
    heading: "App Solutions Made to Achieve Your Goals",
    content:
      "Launch quick, scalable, and easy-to-use apps on any platform. Whether iOS, Android, or hybrid, customize features for your needs.",
  },
  {
    image: require("../assets/icons/social-media-marketing.png"),
    heading: "Strengthen Your Brand & Increase Your Reach",
    content:
      "Strengthen your brand with personalized marketing strategies that deliver results. From SEO and social media to branding and ad campaigns—we’ve got it all.",
  },
];

const PlansComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handleOpen = (plan) => {
    setSelectedPlan(plan.heading);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const [formData, setFormData] = useState({
    serviceCategory: selectedPlan, // Assuming selectedPlan is passed as a prop
    fullName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch(
        "https://fuzzads-main-server.onrender.com/api/choose-plan/submit",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
  
      if (response.ok) {
        alert("Form submitted successfully!");
  
        // Reset form fields to empty values after submission
        setFormData({
          serviceCategory: selectedPlan, // Keep selected plan as default
          fullName: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        alert("Failed to submit the form.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred.");
    }
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <PricingCards />
      <div className="container mb-5 mt-5">
        <div className="row justify-content-center">
          {plansData.map((plan, index) => (
            <div
              className="col-lg-3 col-md-6 col-sm-12 mb-4 d-flex justify-content-center"
              key={index}
            >
              <div
                className="card flex-grow-1"
                style={{
                  border: "4px solid #26017b",
                  borderRadius: "10px",
                  backgroundColor: "transparent",
                  width: "100%",
                  maxWidth: "350px", // Optional: limits card width on larger screens
                }}
              >
                <div className="card-body d-flex flex-column">
                  <div className="d-flex align-items-start">
                    <img
                      src={plan.image}
                      alt={plan.heading}
                      style={{ width: "50px", height: "50px" }}
                      className="me-2"
                    />
                    <h5
                      className="card-title"
                      style={{ color: "#26017b", fontWeight: "bolder" }}
                    >
                      {plan.heading}
                    </h5>
                  </div>
                  <p
                    className="card-text text-center mt-3 mb-4 fst-italic"
                    style={{ color: "#00a5e0", fontWeight: "bold" }}
                  >
                    "{plan.content}"
                  </p>
                  <div className="mt-auto d-flex justify-content-center">
                    <button
                      className="btn"
                      style={{
                        backgroundColor: "#00a5e0",
                        color: "white",
                        border: "2px solid #00a5e0",
                        borderRadius: "100px",
                        fontWeight: "bolder",
                      }}
                      onClick={() => handleOpen(plan)}
                      onMouseEnter={(e) => {
                        e.currentTarget.style.backgroundColor = "white";
                        e.currentTarget.style.color = "#00a5e0";
                      }}
                      onMouseLeave={(e) => {
                        e.currentTarget.style.backgroundColor = "#00a5e0";
                        e.currentTarget.style.color = "white";
                      }}
                    >
                      Get Customized
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* Modal for Popup Form */}
          <Modal
            show={showModal}
            onHide={handleClose}
            centered
            style={{ zIndex: "10000" }}
          >
            <Modal.Header closeButton style={{ borderBottom: "none" }}>
              <Modal.Title
                className="fw-bold fs-4"
                style={{ color: "#26017b" }}
              >
                Customize Your Journey with Us
              </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ padding: "1rem" }}>
              {" "}
              {/* Reduce padding */}
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label
                    htmlFor="serviceCategory"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Service Category
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="serviceCategory"
                    value={selectedPlan}
                    readOnly
                    style={{
                      borderRadius: "100px",
                      border: "2px solid #00a5e0",
                      backgroundColor: "rgba(241, 237, 237, 1)",
                      cursor: "not-allowed",
                      padding: "0.5rem", // Reduce padding
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="fullName"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName} // Bind to state
                    onChange={handleChange}
                    required
                    placeholder="Enter Your Full Name"
                    style={{
                      borderRadius: "100px",
                      border: "2px solid #00a5e0",
                      backgroundColor: "white",
                      padding: "0.5rem", // Reduce padding
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="email"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter Your Email Address"
                    name="email"
                    value={formData.email} // Bind to state
                    onChange={handleChange} // Handle changes
                    required
                    style={{
                      borderRadius: "100px",
                      border: "2px solid #00a5e0",
                      backgroundColor: "white",
                      padding: "0.5rem", // Reduce padding
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="phone"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    name="phone"
                    value={formData.phone} // Bind to state
                    onChange={handleChange} // Handle change
                    placeholder="Enter Your Phone Number"
                    required
                    style={{
                      borderRadius: "100px",
                      border: "2px solid #00a5e0",
                      backgroundColor: "white",
                      padding: "0.5rem", // Reduce padding
                    }}
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="message"
                    className="form-label fw-bold"
                    style={{ color: "#26017b" }}
                  >
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    name="message"
                    placeholder="Write Us Your Needs"
                    value={formData.message} // Bind to state
                    onChange={handleChange} // Handle changes
                    rows="3"
                    style={{
                      borderRadius: "10px",
                      border: "2px solid #00a5e0",
                      backgroundColor: "white",
                      padding: "0.5rem", // Reduce padding
                    }}
                  />
                </div>
                <button
                  type="submit"
                  className="btn"
                  style={{
                    backgroundColor: "#00a5e0",
                    color: "white",
                    borderRadius: "100px",
                  }} // Ensure button is also rounded
                >
                  Submit
                </button>
              </form>
            </Modal.Body>
          </Modal>

          <PlanForm />
        </div>
      </div>
    </>
  );
};

export default PlansComponent;

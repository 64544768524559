import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import call from "../assets/icons/phone-call.png";
import "../styles/services.css";

const servicesData = [
  {
    image: require("../assets/services/web-dev.png"),
    heading: "Web Development",
    content:
      "We design beautiful, easy-to-use websites that bring your brand to life and provide a smooth, engaging experience for your visitors.",
    link: "/get-started-1",
  },
  {
    image: require("../assets/services/digital-marketing.jpg"),
    heading: "Digital Marketing",
    content:
      "Increase your brand’s reach with tailored digital marketing strategies that drive traffic and boost engagement.",
    link: "/get-started-2",
  },
  {
    image: require("../assets/services/app-development.png"),
    heading: "App Development",
    content:
      "We design beautiful, easy-to-use websites that bring your brand to life and provide a smooth, engaging experience for your visitors.",
    link: "/get-started-3",
  },
  {
    image: require("../assets/services/graphic-design.png"),
    heading: "Graphic Designing",
    content:
      "Create eye-catching visuals that make your brand stand out and communicate your message effectively.",
    link: "/get-started-4",
  },
  {
    image: require("../assets/services/social-media.png"),
    heading: "Social Media Marketing",
    content:
      "Engage your audience and grow your brand with creative, targeted social media strategies that deliver results.",
    link: "/get-started-6",
  },
  {
    image: require("../assets/services/seo.jpg"),
    heading: "Search Engine Optimization",
    content:
      "Improve your website’s visibility and drive organic traffic with effective SEO strategies tailored to your business.",
    link: "/get-started-4",
  },
  {
    image: require("../assets/services/artificial-intelligence.png"),
    heading: "AI Integration",
    content:
      "Enhance your website with intelligent AI solutions that automate processes, personalize user experiences, and improve overall efficiency.",
    link: "/get-started-5",
  },
  {
    image: require("../assets/services/personal-management.jpg"),
    heading: "Personal Manager",
    content:
      "Get dedicated, one-on-one support with a personal manager who ensures your projects run smoothly and meet your goals efficiently.",
    link: "/get-started-6",
  },
  {
    image: require("../assets/services/email.png"),
    heading: "Free Business Emails",
    content:
      "Receive up to 10 professional business emails with select plans, giving your brand a more credible and polished online presence.",
    link: "/get-started-6",
  },
];

const Service = () => {
  const leftRightVariants = {
    hidden: { x: "-100vw", opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", stiffness: 50, damping: 20, duration: 1.5 },
    },
  };

  const bottomToTopVariant = {
    hidden: { opacity: 0, y: 100 }, // Start hidden below the original position
    visible: { opacity: 1, y: 0, transition: { duration: 1 } }, // Fade in to the original position
  };

  const rightToLeftVariant = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  };

  const navigate = useNavigate();

  const handleGetStartedClick = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/", { state: { scrollToPricing: true } });
    } else {
      navigate("/create-account");
    }
  };
  const handleButtonClick = () => {
    // Replace '/desired-route' with the route you want to navigate to
    navigate("/contact");
  };

  return (
    <>
      <div className="service-container">
        <div
          className="contact-video d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100px", width: "100%", position: "relative" }}
        >
          <h2
            className="fw-bold text-center"
            style={{
              position: "relative",
              zIndex: 1,
              fontSize: "4rem",
              color: "#26017b",
            }}
          >
            Services
          </h2>
          <p
            className="fst-italic text-center fs-4 fw-bold"
            style={{ color: "#00a5e0" }}
          >
            "Built to grow your business."
          </p>
        </div>

        <div className="services-content text-center my-4 mx-auto" style={{ maxWidth: '1000px' }}>
          <motion.div
            className="container"
            initial="hidden"
            whileInView="visible"
            variants={bottomToTopVariant}
            viewport={{ once: true }}
          >
            <div className="row justify-content-center">
              {servicesData.map((service, index) => (
                <div key={index} className="col-md-4 col-sm-6 mb-4">
                  <div
                    className="card d-flex flex-column"
                    style={{
                      backgroundColor: index % 2 === 0 ? "#00a5e0" : "#26017b",
                      borderRadius: '10px',
                      border: `3px solid ${
                        index % 2 === 0 ? "#26017b" : "#00a5e0"
                      }`,
                      color: "white",
                      fontWeight: "bold",
                      maxWidth: "90%",
                      margin: "0 auto",
                      minHeight: "300px", // Increase this value for more height
                    }}
                  >
                    <div className="card-body d-flex flex-column justify-content-between">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <img
                          src={service.image}
                          alt={service.heading}
                          style={{ width: "50px", height: "50px" }}
                        />
                        <h3
                          className="card-title text-end fw-bold"
                          style={{ fontSize: "1.3rem" }}
                        >
                          {service.heading}
                        </h3>
                      </div>
                      <p className="card-text flex-grow-1">{service.content}</p>
                      <a
                        href="/plans"
                        className="text-white"
                        style={{
                          textDecoration: "none",
                          alignSelf: "flex-start",
                          transition: "transform 0.2s",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.transform = "scale(1.03)")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.transform = "scale(1)")
                        }
                      >
                        Get Started &gt;&gt;
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
        <div
          className="container-fluid d-flex justify-content-center align-items-center"
          style={{ maxHeight: "400px", backgroundColor: "#26017b" }}
        >
          <motion.div
            className="text-center"
            style={{ fontFamily: "Lato, sans-serif" }}
            initial="hidden"
            whileInView="visible"
            variants={bottomToTopVariant}
            viewport={{ once: true }}
          >
            <p
              className="text-white mb-4 mt-2 fs-3 fs-md-4"
              style={{ fontSize: "35px" }}
            >
             Choose a 1-year plan + Get an extra month for free!{" "}
            </p>
            <p
              className="text-white mb-4 fs-6 fs-md-5"
              style={{ marginTop: "-1.5rem" }}
            >
              "Empowering Brands with Digital Brilliance"
            </p>
            <a
            href="/plans"
              className="btn get-startedbtn mb-3 fs-5 fs-md-3"
            >
              Get Started
            </a>
          </motion.div>
        </div>
        <motion.div
          className="container my-4 mt-5 mb-5" // Added container class for centering
          initial="hidden"
          whileInView="visible"
          variants={bottomToTopVariant}
          viewport={{ once: true }}
        >
          <div className="add-services d-flex justify-content-center align-items-center mx-1">
            <a href="tel:+919310930177" style={{ textDecoration: "none" }}>
              <div className="call-container d-flex align-items-center">
                <img
                  src={call}
                  alt="Call Icon"
                  className="call-img me-2"
                  style={{ width: "50px", height: "auto" }}
                />
                <div className="call-text">
                  <h5 className="mb-0">
                    <span className="fw-bold" style={{ color: "#26017b" }}>
                      +91 9310930177
                    </span>{" "}
                    <br />
                    <span style={{ color: "#00a5e0" }}>Talk to our expert</span>
                  </h5>
                </div>
              </div>
            </a>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Service;
